import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хоёрдогч зах зээлийн арилжааны мэдээллийг хэрхэн харах вэ?",
  "description": null,
  "author": "OTC help",
  "category": "market",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хоёрдогч зах зээлийн арилжааны мэдээллийг хэрхэн харах вэ?`}</h2>
    <ol>
      <li parentName="ol">{`Платформын цэснээс `}<strong parentName="li">{`[Market]`}</strong>{` хэсгийг сонгон `}<strong parentName="li">{`[Secondary]`}</strong>{` дэд цэсийг дарна.`}</li>
      <li parentName="ol">{`Сонголт хэсгээс үнэт цаасны төрөл, нэрийг сонгож тухайн үнэт цаасны дэлгэрэнгүй мэдээлэл, авах болон зарах захиалгын самбар, биелсэн гүйлгээний жагсаалтыг харах боломжтой`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      